import { IEvent } from '../models/Event';
import { IGame } from '../models/Game';

export function getLocationSchema(event: IEvent) {
    const { venue_lat, venue_lng, venue_city, venue_zip, venue_address, venue_name } = event
    return {
        "@type": "Place",
        "address": {
          "@type": "PostalAddress",
          "addressLocality": venue_city,
          "postalCode": venue_zip,
          "streetAddress": venue_address
        },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": venue_lat,
          "longitude": venue_lng,
        },
        "name": venue_name
    }
}

export function getEventSchema(event: IEvent) {
    return {
        "@context": "https://schema.org",
        "@type": "Event",
        "location": getLocationSchema(event),
        "name": event.title,
        "startDate": event.start_date,
        "endDate": event.end_date,
        // "image": "EVENT_IMAGE",
        "description": event.information,
    }
}

export function getGameSchema(game: IGame, event: IEvent) {
    return {
        ...getEventSchema(event),
        "@type":"SportsEvent",
        "sport": game.sport?.name,
        "awayTeam": {
            "@type": "SportsTeam",
            "name": game.team1?.name
        },
        "homeTeam": {
            "@type": "SportsTeam",
            "name": game.team2?.name
        },
    }
}