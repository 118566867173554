import { useClub } from 'contexts/club';
import { usePage } from 'contexts/page';
import Head from 'next/head';
import DescriptionMeta from './DescriptionMeta';

export interface ITitleMetaProps {
    title?: string
    pageTitleSuffix?: boolean
}

function TitleMeta(props: ITitleMetaProps) {
    const { pageTitleSuffix = true } = props
    const { page } = usePage()
    const { club } = useClub()
    const prefix = club.title
    const suffix = page?.title
    const title = [prefix, props.title, pageTitleSuffix && suffix].filter(Boolean).join(' - ')
    return (
        <>
            <Head>
                <title key="title">{title}</title>
                <meta property="og:title" content={title} key="ogtitle" />
                <meta name="twitter:title" content={title} key="twittertitle" />
                <meta name="twitter:image:alt" content={title} key="twitterimagealt" />
            </Head>
            <DescriptionMeta description={title} />
        </>
    )
}

export default TitleMeta
