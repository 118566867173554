import Head from 'next/head';

interface IDescriptionMeta {
    description: string
}

export default function DescriptionMeta(props: IDescriptionMeta) {
    const { description } = props
    if(!description)
        return null
    return (
        <Head>
            <meta name="description" content={description} key="description" />
            <meta property="og:description" content={description} key="ogdescription" />
            <meta name="twitter:description" content={description} key="twitterdescription" />
        </Head>
    )
}