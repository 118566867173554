import Head from 'next/head';

interface IImageMetaProps {
    image: string
}

export default function ImageMeta(props: IImageMetaProps) {
    const { image } = props
    if(!image) {
        return null
    }
    return (
        <Head>
            <meta property="og:image" content={image} key="ogimage" />
            <meta name="twitter:image" content={image} key="twitterimage" />
        </Head>
    )
}