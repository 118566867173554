import Head from 'next/head';

interface IVideoMeta {
    video: string
}

export default function VideoMeta(props: IVideoMeta) {
    const { video } = props

    if(!video)
        return null

    return (
        <Head>
            <meta property="og:type" content="video.other" key="ogtype"/>
            <meta property="og:video:width" content="1200" key="ogvideowidth"/>
            <meta property="og:video:height" content="720" key="ogvideoheight"/>
            <meta property="og:video" content={video} key="ogvideo"/>
        </Head>
    )
}