import Head from 'next/head';

interface ISchemaMeta {
    schema: object
}

export default function SchemaMeta(props: ISchemaMeta) {
    const { schema } = props
    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
                key='schema'
            />
        </Head>
    )
}